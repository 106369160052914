function handleImageRefToUrl(ref) {
  const converted = process.env.SANITY_IMAGE_BASE_URL
    .concat(ref
      .replace('image-', '')
      .replace('-jpg', '.jpg')
      .replace('-png', '.png'));
  return converted;
}

export default handleImageRefToUrl;
