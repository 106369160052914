import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  Title,
  Text,
  Icon,
  Image,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

import handleImageRefToUrl from '../../../functions/handleImageRefToUrl';
import handleHeadingPrioritization from '../../../functions/handleHeadingPrioritization';

const QuoteIcon = ({
  type,
  width,
  verticalLayout: isVerticalLayout,
}) => (
  <div
    style={{
      height: 20,
      width: 20,
      position: 'absolute',
      left: type === 'left' ? (isVerticalLayout ? 25 : width + 25) : 'inherit',
      top: type === 'left' ? 15 : 'inherit',
      right: type === 'right' ? 25 : 'inherit',
      bottom: type === 'right' ? 15 : 'inherit',
    }}
  >
    <Icon
      name={type === 'left' ? 'QuoteLeft' : 'QuoteRight'}
      height={20}
      width={20}
      color="#CAE5F2"
    />
  </div>
);

export default function Quote(props) {
  const [active, setActive] = React.useState(0);

  const { i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;
  const hasBlueprint = props.params.blueprint !== 'single';
  const isVerticalLayout = (hasBlueprint || size.width < Breakpoint.sm);

  const ref = React.useRef(null);
  const isMounted = React.useRef(false);

  const [isAnimating, setAnimating] = React.useState(false);
  const [texts, setTexts] = React.useState({
    image: null,
    text: props.data.array[0].quote_text,
    partner: props.data.array[0].partner,
    company: props.data.array[0].company,
    function: props.data.array[0].function,
  });

  React.useEffect(() => {
    if (props.data.array.length !== 0) {
      setTexts({
        /* eslint-disable-next-line no-underscore-dangle */
        image: handleImageRefToUrl(props.data.array[0].image.asset._ref),
        text: props.data.array[0].quote_text,
        partner: props.data.array[0].partner,
        company: props.data.array[0].company,
        function: props.data.array[0].function,
      });
    }
  }, [props.data.array]);

  React.useEffect(() => {
    /*
      A addition of 3.5s to equalize the time the quotes are in standstill.
      This difference between the first and the rest of the quotes is because
      the initial quote doesn't have an animation which makes it's duration longer.
      This counteracts that.

      isMounted.current = true === time between quote including animations time
      isMounted.current = false === initial time
    */
    const delay = isMounted.current ? 9500 : 6000;
    const quotes = props.data.array;
    const arrayLength = quotes.length;
    const currentIteration = (active + 1) === arrayLength ? 0 : (active + 1);

    isMounted.current = true;

    setTimeout(() => {
      setActive(currentIteration % arrayLength);
      setAnimating(true);
    }, delay);

    setTimeout(() => {
      if (props.data.array.length !== 0) {
        setTexts({
          /* eslint-disable-next-line no-underscore-dangle */
          image: handleImageRefToUrl(props.data.array[active].image.asset._ref),
          text: props.data.array[active].quote_text,
          partner: props.data.array[active].partner,
          company: props.data.array[active].company,
          function: props.data.array[active].function,
        });
      }
    }, 1000);
  }, [active]);

  return (
    <motion.div
      style={{
        height: 'auto',
        width: '100%',
        position: 'relative',
        paddingTop: isVerticalLayout && !props.data.title ? 90 : 0,
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      {
        props.data.title && (
          <div
            style={{
              marginBottom: isVerticalLayout ? 90 : 30,
            }}
          >
            <Title
              textAlign={isVerticalLayout ? 'center' : 'left'}
              heading={handleHeadingPrioritization(props.params.index)}
            >
              {props.data.title[lang]}
            </Title>
          </div>
        )
      }
      <motion.div
        ref={ref}
        style={{
          height: isVerticalLayout ? 350 : 250,
          width: '100%',
          backgroundColor: Colors.GRADIENT,
          borderRadius: 10,
          display: 'flex',
          flexDirection: isVerticalLayout ? 'column' : 'row',
          justifyContent: 'flex-start',
          position: 'relative',
          transition: 'all .3s ease',
        }}
      >
        {
          isVerticalLayout ? (
            <div
              style={{
                position: 'relative',
                height: 50,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: -95,
                  left: 0,
                  right: 0,
                }}
              >
                <Image
                  wrapper="Figure"
                  // src doesn't show anything currently
                  src={texts.image !== undefined && `${texts.image}?q=50`}
                  id={props.params.id}
                  height={190}
                  width={190}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                height: '100%',
                width: 240,
                backgroundImage: texts.image !== undefined && `url('${texts.image}?q=50')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                overflow: 'hidden',
                zIndex: 9,
              }}
            >
              {/* Profile foto overlay animation */}
              <motion.div
                initial={{
                  x: '-100%',
                }}
                animate={{
                  x: isAnimating ? 0 : '-100%',
                }}
                transition={{
                  duration: 1,
                  ease: 'linear',
                  repeat: 1,
                  repeatType: 'reverse',
                  repeatDelay: 0.25,
                }}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '102%',
                  marginTop: '-1%',
                  backgroundColor: Colors.BLUE,
                  zIndex: 10,
                }}
              />
            </div>
          )
        }
        <div
          style={{
            height: '100%',
            width: '100%',
            paddingLeft: isVerticalLayout ? 25 : 265,
            paddingRight: 25,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: 'hidden',
          }}
        >
          <QuoteIcon
            type="left"
            size={size}
            width={240}
            verticalLayout={isVerticalLayout}
          />
          <motion.div
            initial={{
              x: 0,
            }}
            animate={{
              x: isAnimating ? '-105%' : 0,
            }}
            transition={{
              duration: 1,
              ease: 'linear',
              repeat: 1,
              repeatType: 'reverse',
              repeatDelay: 0.25,
            }}
            onAnimationComplete={() => setAnimating(false)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Text
              portableText
              lineHeight={1.3}
            >
              {texts.text[lang]}
            </Text>
            <div
              style={{
                marginTop: 30,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Text
                fontFamily="Caveat"
                fontSize="1.7em"
              >
                {texts.partner}
                {' - '}
                {texts.function !== undefined && `${texts.function[lang]}, `}
                {texts.company}
              </Text>
            </div>
          </motion.div>
          <QuoteIcon
            type="right"
            size={size}
            width={240}
            verticalLayout={isVerticalLayout}
          />
        </div>
      </motion.div>
    </motion.div>
  );
}
